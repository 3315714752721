import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { useTranslation } from 'react-i18next';

const ContactUs = () => {
    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
        images: [],
    });

    const [formStatus, setFormStatus] = useState({ success: null, message: '' });
    const [loading, setLoading] = useState(false); // Loader state

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });

    const handleFileChange = async (e) => {
        const files = Array.from(e.target.files);
        try {
            const base64Files = await Promise.all(files.map((file) => toBase64(file)));
            setFormData((prevData) => ({
                ...prevData,
                images: base64Files,
            }));
        } catch (error) {
            console.error('Error converting files to Base64:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loader

        const data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            phone: formData.phone,
            message: formData.message,
            attachments: formData.images,
        };

        try {
            const response = await fetch('https://api.leased.homes/index.php/api/v1/contact-form-submission', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();

            if (response.ok && result.success) {
                setFormStatus({ success: true, message: result.message });
                setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '',
                    message: '',
                    images: [],
                });
            } else {
                setFormStatus({ success: false, message: result.message || t('form_submit_error') });
            }
        } catch (error) {
            setFormStatus({ success: false, message: t('form_submit_error') });
        }

        setLoading(false); // Stop loader
    };

    return (
        <Layout>
            <div className="container my-24 px-6 mx-auto">
                <section className="mb-32 text-gray-800">
                    <div className="flex flex-wrap">
                        <div className="grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
                            <h2 className="lg:text-5xl text-4xl font-bold mb-6">{t('contact_us')}</h2>
                            <p className="text-gray-500 mb-6">{t('contact_text1')}</p>
                            <p className="text-gray-500 mb-2">{t('contact_text2')}</p>
                            <p className="text-gray-500 mb-2">{t('contact_text3')}</p>
                            <p className="text-gray-500 mb-2">Support@leased.homes</p>
                        </div>
                        <div className="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-2 gap-5">
                                    <input
                                        type="text"
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        placeholder={t('contact_first_name')}
                                        className="form-control block w-full px-3 py-1.5 border rounded mb-4"
                                    />
                                    <input
                                        type="text"
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        placeholder={t('contact_last_name')}
                                        className="form-control block w-full px-3 py-1.5 border rounded mb-4"
                                    />
                                </div>
                                <div className="grid grid-cols-2 gap-5">
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        placeholder={t('contact_email')}
                                        className="form-control block w-full px-3 py-1.5 border rounded mb-4"
                                    />
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleInputChange}
                                        placeholder={t('contact_phone')}
                                        className="form-control block w-full px-3 py-1.5 border rounded mb-4"
                                    />
                                </div>
                                <div className="mb-4 border rounded-md px-2 py-2">
                                    <input type="file" multiple onChange={handleFileChange} />
                                </div>
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    placeholder={t('contact_message')}
                                    className="form-control block w-full px-3 py-1.5 border rounded mb-4"
                                    rows="3"
                                ></textarea>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full px-6 py-3 bg-[#F79F15] text-white rounded flex justify-center items-center"
                                >
                                    {loading ? (
                                        <span className="loader mr-2 border-2 border-t-2 border-white rounded-full h-5 w-5 animate-spin"></span>
                                    ) : (
                                        t('contact_submit')
                                    )}
                                </button>
                            </form>
                            {formStatus.message && (
                                <div
                                    className={`mt-4 p-3 ${
                                        formStatus.success ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                                    }`}
                                >
                                    {formStatus.message}
                                </div>
                            )}
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default ContactUs;