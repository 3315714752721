import React, { useState } from 'react'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'
import { useTranslation } from 'react-i18next'

const SettingFaq = () => {
    const [active, setActive] = useState(null)

    const handleToggle = (i) => {
        if (i === active) {
            setActive(null)
        } else {
            setActive(i)
        }
    }

    const { t } = useTranslation()
    const listFaq = [t('faq_1'), t('faq_2'), t('faq_3'), t('faq_4'), t('faq_5'), t('faq_6'),]
    const farAnswers = [t('faq_text1'), t('faq_text2'), t('faq_text3'), t('faq_text4'), t('faq_text5'), t('faq_text6')]
    return (
        <>
            <div className="container mx-auto mt-7 xl:pb-0 lg:pb-0 md:pb-0 pb-10">
                <div className="-mx-4 flex flex-wrap">
                    <div className="w-full px-4">
                        <div className="mx-auto xl:mb-[60px]  md:mb-[60px] mb-5 max-w-[520px] text-center lg:mb-20">
                            <span className="mb-2 block font-bold xl:text-5xl lg:text-5xl md:text-5xl text-[25px]">
                                {t('faq')}
                            </span>
                            <h2
                                className="mb-4 text-xl font-bold text-dark sm:text-4xl md:text-[40px]"
                            >
                                {t('any_look')}
                            </h2>
                            <p className="text-base text-body-color">
                                {t('any_look_text')}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full px-4 ">
                    <div className='grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-5 lg:gap-5 md:gap-5 gap-2'>
                        {
                            listFaq?.map((item, i) => (

                                <div
                                    className=" xl:mb-8 lg:mb-8 md:mb-8 mb-2 w-full rounded-lg border border-[#F3F4FE] bg-white p-4 sm:p-8 lg:px-6 xl:px-8"
                                    key={i}
                                >
                                    <button
                                        className=" flex w-full text-left"
                                    >
                                        <div
                                            className="mr-5 flex h-10 w-full max-w-[40px] items-center justify-center rounded-lg bg-blue-500 bg-opacity-5 text-blue-500"
                                            onClick={() => handleToggle(i)}
                                        >
                                            {
                                                active === i ?
                                                    <IoIosArrowDown className='text-[20px] font-bold' />
                                                    :
                                                    <IoIosArrowUp className='text-[20px] font-bold' />
                                            }
                                        </div>
                                        <div className="w-full">
                                            <h4 className="text-md font-semibold text-black">
                                                {item}
                                            </h4>
                                        </div>
                                    </button>
                                    {
                                        active === i &&
                                        <div className=" pl-[62px]">
                                            <p
                                                className="py-3 text-base leading-relaxed text-body-color"
                                                dangerouslySetInnerHTML={{ __html: farAnswers[i] }}
                                            />
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>

                </div >
            </div >

        </ >
    )
}

export default SettingFaq