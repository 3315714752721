import React, { useState, useEffect } from 'react'
import DownloadApp from './DownloadApp'
import { AiOutlineInstagram } from 'react-icons/ai'
import { BsTwitter, BsYoutube } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import { GrLinkedinOption } from 'react-icons/gr'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
// import { BsYoutube} from 'react-icons/ai'
const Footer = () => {
    const { t } = useTranslation()


    const [activeTabText, setActiveTabText] = useState()

    const lng = useSelector((state) => state?.userAuth?.language)
    const navigate = useNavigate()

    const openNewTab = (url) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
    };


    useEffect(() => {
        if (activeTabText === t('right_responsive')) {
            const url = lng === 'sp' ? 'https://ctlawhelp.org/es/derechos-de-los-inquilinos-informacion-general' : 'https://www.jud.ct.gov/Publications/hm031.pdf';
            openNewTab(url)
        }
        else if (activeTabText === t('eviction_guid')) {
            const url = lng === 'sp' ? 'https://www.jud.ct.gov/Publications/Spanish/HM014S.pdf' : 'https://www.jud.ct.gov/Publications/hm014.pdf';
            openNewTab(url)
        }
        else if (activeTabText === t('leased_base')) {
            const url = lng === 'sp' ? 'https://www.epa.gov/lead/declaracion-de-informacion-sobre-pintura-base-de-plomo-yo-peligros-de-la-pintura-base-de-plomo' : 'https://www.epa.gov/lead/lessors-disclosure-information-lead-based-paint-andor-lead-based-paint-hazards';
            openNewTab(url)
        } else {
            navigate(activeTabText)
        }
    }, [activeTabText])

    const links = lng === 'sp' ? "/sp/terms" : '/terms'
    const privacy_link = lng === 'sp' ? "/sp/privacy" : '/privacy'

    const footerList = [
        { heading: t('company'), list: [{ name: t('about_us'), link: "/aboutus" }] },
        {
            heading: t('product_support'), 
            list: [
                { name: t('terms_service'), link: links }, 
                { name: t('contact_us'), link: "/aboutus/contactus" }, 
                { name: t('privacy_ploicy'), link: privacy_link },
                { name: t('user_guide_renter'), link: "https://leased.s3.amazonaws.com/Leased+User+Guide+Servicer.pdf", new_tab: true },
                { name: t('user_guide_servicer'), link: "https://leased.s3.amazonaws.com/Leased+User+Guide+Customer.pdf", new_tab: true  }
            ]
        },
        { heading: t('safety'), list: [{ name: t('right_responsive'), link: t('right_responsive') }, { name: t('leased_base'), link: t('leased_base') }, { name: t('eviction_guid'), link: t('eviction_guid') }] },
        {
            heading: t('discover'), list: [{ name: t('faq'), link: "/aboutus/faq" }
                // t('feature'), t('host_list'), t('what_new'),
            ]
        },
    ]


    return (
        <>

            <div className=' mt-[3rem] relative '>
                <DownloadApp />
                <div className='bg-[#F5F6FC] border -mt-[40px] pb-2'>
                    <div className='container mx-auto'>
                        <div className='grid lg:grid-cols-4 grid-cols-2 gap-0 pt-[4rem]'>
                            {footerList.map((d, i) => (
                                <div className="flex flex-col items-start mx-8" key={i}>
                                    <h2 className='font-bold underline text-[22px]'>{d.heading}</h2>
                                    <ul className='text-left  '>
                                        {d.list.map((item, index) => {
                                            if(Object.hasOwn(item, 'new_tab') && item.new_tab == true){
                                                return (
                                                    <li onClick={() => window.open(item.link)} className='cursor-pointer' key={index}> {item?.name}</li>
                                                )
                                            }else{
                                                return (
                                                    <li onClick={() => setActiveTabText(item.link)} className='cursor-pointer' key={index}> {item?.name}</li>
                                                )
                                            }
                                        })}
                                    </ul>

                                </div>
                            ))}
                        </div>
                        <div className='flex items-center lg:my-0 lg:mx-0 mx-3 mt-3'>
                            <a href="https://www.instagram.com/leasedhomes/" target="_blank" className='bg-white rounded-xl  mx-3 px-1 py-1 shadow-md'>
                                <AiOutlineInstagram className='lg:text-[25px] text-[18px]' />
                            </a>
                            <a href="https://x.com/LeasedHomes" target="_blank" className='bg-white rounded-xl  mx-3 px-1 py-1 shadow-md'>
                                <BsTwitter className='lg:text-[25px] text-[18px]' />
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=100089231920374" target="_blank" className='bg-white rounded-xl  mx-3 px-1 py-1 shadow-md'>
                                <FaFacebookF className='lg:text-[25px] text-[18px]' />
                            </a>
                            <a href="https://www.linkedin.com/company/leasedhomes/posts/?feedView=all" target="_blank" className='bg-white rounded-xl  mx-3 px-1 py-1 shadow-md'>
                                <GrLinkedinOption className='lg:text-[25px] text-[18px]' />
                            </a>
                            <a href="https://www.youtube.com/@Leasedhomes" target="_blank" className='bg-white rounded-xl  mx-3 px-1 py-1 shadow-md'>
                                <BsYoutube className='lg:text-[25px] text-[18px]' />
                            </a>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default Footer