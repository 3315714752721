import React, { useState, useEffect } from 'react'
import { Loader } from '../../utils/loaderButton'
import { useTranslation } from 'react-i18next'
import {
    // GetCountProperty,
    GetRental,
    GetRommates,
    GetServicerMainFlashProperty,
    GetServicerMainReasyProperty
} from '../../lib/Api/RantelServiceApi'
import propImg from '../../assests/svg/home.svg'
import { Link, useLocation } from 'react-router-dom'
import Select from 'react-select'
const PropertiesList = ({ activeTab, setProperty, setAllRentals, setAddmore, purchasedHistory,
    setPurchaseInfo }) => {
    const [loading, setLoading] = useState(false)
    const [propertyList, setPropertyList] = useState([])
    const [selectedProperty, setSelectProperty] = useState({})
    const [roomateStatus, setRommateStatus] = useState(false)
    const [rommateLists, setRommateLists] = useState([])

    const location = useLocation().pathname

    let fetchServicer = async () => {
        setLoading(true)
        let res = await (location === "/servicer_flash" ? GetServicerMainFlashProperty() : GetServicerMainReasyProperty())
        if (res) {
            let obj = res && Object?.assign({}, ...res)
            setLoading(false)
            let itemList = obj.my_rentals?.map((list) => {
                return {
                    value: list?.id,
                    label: `${list?.apt !== null ? `Apt #${list?.apt}` : ''} ${list?.complete_address}`
                }
            })
            setRommateStatus(false)
            setPropertyList(itemList)
            if (itemList?.length === 0) {
                setTimeout(() => {
                    setPurchaseInfo(purchasedHistory)
                }, 3000);
            }
        } else {
            setLoading(false)
        }
    }

    let fetchData = async () => {
        setLoading(true)
        let { res } = await GetRommates()
        if (res) {
            setLoading(false)
            setRommateLists(res?.roommates)
            if (Object.keys(res?.status ?? {}).length > 0) {
                let check = Object.values(res?.status).some(value => value === false);
                if (check) {
                    setLoading(false)
                    setRommateStatus(true)
                    setPropertyList([])
                } else {
                    setRommateStatus(false)
                    setProperty("rommate")
                    setPropertyList([])

                }
            } else {
                setLoading(false)
                setRommateStatus(true)
                setPropertyList([])
            }
        }
    }

    useEffect(() => {
        if (activeTab === 0) {
            fetchServicer()
        }
        else {
            fetchData()
        }
    }, [activeTab])

    const handleProperty = () => {
        setProperty(selectedProperty)
        setAddmore(false)
    }

    const { t } = useTranslation()
    return (
        <>
            {loading ? <Loader type="loadData" /> :
                <>
                    {!loading && propertyList?.length > 0 &&
                        <div className="bg-white rounded-md mt-5 ">
                            <div className="h-full  ">

                                <h2 className='py-3 font-semibold text-[20px]'> {location === "/flash" || location === "/servicer_flash" ? t('add_flash') : t('add_reasy')}</h2>
                                <Select
                                    isMulti
                                    placeholder={t('please_select')}
                                    options={propertyList}
                                    onChange={setSelectProperty}
                                />
                                {/* {location === "/servicer_flash" && (
                                    <div onClick={() => setProperty("addRommate")} className={`text-[#F79F15] font-medium text-[16px] pt-2 cursor-pointer`}>{t('add_for_rommate')}</div>
                                )} */}

                                <div className='max-w-[300px] mx-auto py-5'>
                                    <button disabled={selectedProperty !== null ? false : true} onClick={() => handleProperty()} className={` ${location === "/servicer_flash" ? 'bg-[#FFDD00] text-black' : 'bg-[#B913F0] text-white'}  flex justify-center items-center w-full disabled:bg-opacity-[0.5] px-6 py-3 rounded-md`}>{t('next_lbl')} </button>
                                </div>
                            </div>

                        </div>
                    }
                    {!loading && propertyList?.length === 0 && roomateStatus === false &&
                        <div className='flex flex-col pt-10'>
                            <div className="flex flex-row justify-center items-center">
                                <div className="flex flex-col justify-center items-center">
                                    <img src={propImg} className='w-[40%]' alt="property_img" />
                                    <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{location === "/servicer_reazy" ? t('reasy_not_found') : t('flash_not_found')}</h2>
                                </div>

                            </div>
                            <div className='w-[200px] mt-5  mx-auto'>
                                <Link to="/register" >
                                    <button className='border  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{
                                        t('add_rental')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    }
                    {!loading && roomateStatus === true &&
                        <div className='flex flex-col pt-10'>
                            <div className="flex flex-row justify-center items-center">
                                <div className="flex flex-col justify-center items-center">
                                    <img src={propImg} className='w-[40%]' alt="property_img" />
                                    <h2 className='font-semibold text-[20px] pt-3 max-w-[250px] text-center'>{rommateLists && Object.keys(rommateLists)?.length > 0 && location === "/servicer_flash" ? t('rommate_not_found_step_flash') : t('rommate_not_found_flash') || rommateLists && Object.keys(rommateLists)?.length > 0 && location === "/servicer_reazy" ? t('rommate_not_found_step') : t('rommate_not_found')}</h2>
                                </div>

                            </div>
                            <div className='w-[200px] mt-5  mx-auto'>
                                <Link to="/roommatesList" >
                                    <button className='border  w-full mt-5 py-2 px-6 rounded-md text-white   bg-[#F79F15]'>{
                                        rommateLists && Object.keys(rommateLists)?.length > 0 ? t('cmpl_profil') : t('add_new_property')}
                                    </button>
                                </Link>
                            </div>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default PropertiesList