import React from 'react'
import Layout from '../../components/Layout/Layout'
import Information from '../../components/AboutUsComp/Infromation'
import Services from '../../components/LandingComp/Services'
import Rentals from '../../components/LandingComp/Rentals'
import Partners from '../../components/LandingComp/Partners'
// import aboutBnaner from '../../assests/images/aboutBanner.png'
import {useTranslation} from 'react-i18next'

const AboutUs = () => {
  const {t} = useTranslation();

  return (
    <Layout>
      <Information t={t}/>
      <Partners/>
      <Services/>
      <Rentals/>
      {/* <div className='container mx-auto py-8'>
        <img src={aboutBnaner} alt="banner"/>
      </div> */}
    </Layout>
  )
}

export default AboutUs